/* Hero Section */
.about-hero,
.team-hero {
  background: center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.about-hero {
  background-image: url(../img/abouthero-Cn_WClbY.png);
  height: 35vh;
}

.team-hero {
  background-image: url(../img/team-hero-BFhKUL_3.jpg);
  height: 60vh;
  color: #fff;
  position: relative;
}

.team-hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: brightness(50%);
  z-index: -1;
}

.about-title,
.team-title {
  font-size: xx-large;
  color: #fff;
  text-align: center;
  z-index: 1;
}

@media (max-width: 1200px) {
  .about-hero {
    height: 30vh;
  }

  .team-title {
    font-size: 2.5em;
  }

  .about-title {
    font-size: x-large;
  }
}

@media (max-width: 768px) {
  .about-hero {
    height: 25vh;
  }

  .team-title,
  .about-title {
    font-size: x-large;
  }
}

@media (max-width: 480px) {
  .team-title {
    font-size: 1.5em;
  }
}

/* About Text Container */
.about-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.about-text-container p {
  width: 80vw;
  font-size: larger;
  text-align: center;
  font-family: Karla, sans-serif;
  line-height: 1.6;
}

/* Gallery */
.gallery-box {
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.row-gallery {
  display: flex;
  overflow-x: auto;
  width: 75vw;
}

.row-gallery .column {
  flex: 0 0 auto;
  width: 25%;
  padding: 10px;
}

.row-gallery .column img {
  opacity: 0.8;
  cursor: pointer;
  width: 100%;
}

img,
svg {
  vertical-align: middle;
}

/* Team Section */
.team-text {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.team-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.team-text .title {
  font-size: xx-large;
  text-align: center;
  font-weight: 600;
  color: #3a0ca3;
}

.team-text .text {
  font-size: larger;
  font-weight: 400;
  text-align: center;
}

.team-members-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-members-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 80%;
}

.member-card {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 30px;
  background-color: #f0e9f0;
  padding: 30px;
  color: black;
  border-radius: 10px;
  box-shadow: 0 5px 10px #00000059;
}

.member-card .member-pic {
  display: flex;
}

.member-card .member-pic .box {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  background-color: rgb(218, 218, 218);
}

.member-card .member-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
}

.member-card .member-text-box .member-title {
  font-size: x-large;
  font-weight: 500;
}

.member-card .member-text-box .member-text {
  font-size: large;
}

@media (max-width: 800px) {
  .member-card {
    grid-template-columns: auto;
    row-gap: 30px;
  }
}

@media (max-width: 450px) {
  .member-card .member-pic .box {
    width: 80%;
  }
}

/* Services Section */
.we-offer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 80px 10px;
}

.services-grid {
  display: grid;
  grid-template-columns: 25% 25% 25%;
  justify-content: center;
  column-gap: 30px;
  row-gap: 20px;
}

@media (max-width: 800px) {
  .services-grid {
    grid-template-columns: 40% 40%;
  }
}

@media (max-width: 450px) {
  .services-grid {
    grid-template-columns: 80%;
  }
}

.card {
  position: relative;
  background: #f0f0f0;
  box-shadow: 0 6px 8px #0000001a;
  overflow: hidden;
  transition: transform 0.3s;
  border-radius: 10px;
}

.content {
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.content .icon {
  font-size: xx-large;
  text-align: center;
}

.content .title {
  font-size: x-large;
  text-align: center;
}

.content .description {
  font-size: large;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #130535b8;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.card:hover .overlay {
  opacity: 1;
}

.learn-more {
  background-color: #3a0ca3;
  border: none;
  font-family: Oswald;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}
.learn-more a {
  color: #f0f0f0;
  text-decoration: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  border-radius: 10px;
  line-height: inherit;
}

/* from here feeds or testimonial part is start css */

.about-hero {
  background: url(../img/abouthero-Cn_WClbY.png) center / cover no-repeat;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}
@media (max-width: 1200px) {
  .about-hero {
    height: 30vh;
  }
}
@media (max-width: 768px) {
  .about-hero {
    height: 25vh;
  }
}

@media (max-width: 768px) {
  .about-hero .about-title {
    font-size: x-large;
  }
}
@media (max-width: 1200px) {
  .about-hero .about-title {
    font-size: x-large;
  }
}
.about-hero .about-title {
  font-size: xx-large;
  color: #fff;
  text-align: center;
}

.feed-tem {
  min-height: 70dvh;
  width: 100%;
  background-color: #e8e8e8;
}

.feed-title {
  text-align: center;
  padding: 5vh 0 2vh 0;
}

.feed-box {
  display: flex;
  flex-direction: row;
  padding: 20px 50px;
  justify-content: space-evenly;
}

.box-img {
  width: 120px; /* Adjust the width as needed */
  margin-right: 15px;
  flex-shrink: 0;
}

.box-img img {
  width: 100%;
  height: auto;
  border-radius: 9px;
}

.box {
  display: flex;
  width: 600px;
  height: max-content;
  background-color: rgba(255, 255, 255, 1);
  border-width: 1px;
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  padding: 1rem;
  margin: 5vh 0;
  cursor: pointer;
  align-items: center;
}

.feed-content h1 strong {
  font-size: 1.5rem;
  font-family: system-ui;
}
.feed-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feed-content h1 {
  margin-bottom: 10px;
  font-size: 1em;
  font-family: system-ui;
}

.feed-content p {
  font-size: 0.9em;
  font-family: system-ui;
  line-height: 1.4;
  margin: 0;
}

@media (max-width: 1290px) {
  .box {
    width: 500px;
  }
}

@media (max-width: 1160px) {
  .box {
    width: 400px;
  }
}

@media (max-width: 960px) {
  .box {
    width: 600px;
  }
  .feed-box{
    display: grid;
  }
}

@media (max-width: 670px) {
    .box {
      width: 490px;
    }
    .feed-box{
      display: grid;
    }
  }

  @media (max-width: 570px) {
    .box {
      width: 300px;
      flex-direction: column;
    }
    .box-img{
        width: 100%;
        margin-right: 0;
        margin-bottom: 3vh;
    }
    .feed-box{
      display: grid;
    }
  }



.hm-cs{
   
    position: relative;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    overflow: hidden;
}

.hm-cs:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../img/mn1-bg.jpg) center / cover no-repeat;
    background-size: cover;
    filter: brightness(50%);
    z-index: -1;
}


.hm-t{
    z-index: 1;
    position: relative;
    text-align: center;

}

.hm-t h1{
    font-size: var(--txt-size-large);
}


.hm-t button{
    margin-top: 2rem;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: larger;
    background-color: #3a0ca3;
    color: white;
    border: none;
    cursor: pointer;
}

.company-profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px;
    gap: 20px;
    justify-content: space-around;
    text-align: flex-start;
}

.company-profile .cp-img {
    display: flex;
    justify-content: center;
    padding: 15px;
}

@media (max-width: 1100px) {
.company-profile .cp-img, .cp-text-container {
    padding: 10px;
}
.cp-img{
    scale: 1.1;
    margin: 4vh;
}
}
.cp-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
}
.cp-img{
    scale: 1.2;
    margin: 6vh 0;
}


.company-profile .cp-text .cp-para {
    font-size: 20px;
    letter-spacing: 1px;
    word-spacing: 1px;
    margin: 20px 0 !important;
    font-family: Karla, sans-serif;
    line-height: 1.9;
    font-weight: 400;
    color: var(--bs-body-color);
    -webkit-text-size-adjust: 100%;
}

@media (max-width: 1100px) {
    .company-profile .cp-text .cp-para, .company-profile ul li {
        font-size: large;
    }
}

.company-profile ul {
    margin-top: 20px;
}

.company-profile ul {
    list-style-type: none;
    padding: 0;
}

.company-profile ul li {
    margin: 15px 0;
    font-family: sans-serif;
    font-size: 22px;
    color: var(--bs-body-color);
    font-weight: 100;
    display: flex;
    align-items: center;
    gap: 10px;
}
@media (max-width: 1100px) {
    .company-profile .cp-text .cp-para, .company-profile ul li {
        font-size: large;
    }
}

.cp-text h3 {
    font-size: 32px;
    color: rgb(105, 83, 156);
}


/* third part */

.services-bx {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 30px 20px;
    overflow: hidden;
}

.services-bx:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../img/services-Ba8qUPbL.png) center / cover no-repeat;
    filter: brightness(40%);
    z-index: -1;
}

.services-bx .title {
    border: none;
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
}
.h2, h2 {
    font-size: calc(1.325rem + .9vw);
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    color: var(--bs-heading-color);
}

.services {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
    overflow: hidden;
}

.wcu-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    justify-content: center;
    background: #0000005c;
    border: 1px solid white;
    padding: 22px 20px;
    width: 100%;
}
.wcu-item .name {
    font-size: x-large;
    color: #f0f8ff;
    font-family: Karla, sans-serif;
}

.wcu-item .desc {
    font-size: larger;
    font-weight: 100;
    color: #f0f8ffc8;
    font-family: Karla, sans-serif;
}

@media (max-width: 768px) {
    .company-profile {
        padding: 20px;
    }
    .company-profile .cp-img, .cp-text-container {
        padding: 5px;
    }
    .company-profile .cp-text h3 {
        font-size: x-large;
    }

    .company-profile .cp-text .cp-para, .company-profile ul li {
        font-size: medium;
    }
    .cp-img{
        scale: 1;
        margin: 2vh 0;
    }
}

@media (max-width: 900px) {
    .company-profile {
        grid-template-columns: 1fr;
    }
}
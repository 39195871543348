.full-head {
  width: 100%;
  height: max-content;
}

.contact-info {
  display: flex;
  flex-direction: row-reverse;
  gap: 30px;
  background-color: #3a0ca3;
  color: #fff;
  padding: 8px 25px;
  font-size: var(--txt-size-small);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
}

.logo img {
  max-width: 60%;
  height: auto;
}

.nav-block {
  display: flex;
  align-items: center;
  gap: 45px;
}

.nav-option a {
  text-decoration: none;
  color: black;
  font-size: var(--txt-size-small);
}

.contact button {
  padding: 5px 20px;
  border-radius: 5px;
  font-size: larger;
  background-color: #3a0ca3;
  color: var(--primary-color);
  border: none;
  cursor: pointer;
}

.contact-form-container {
  background: url(../img/contactus-QW1mBBua.png) center / cover no-repeat;
  background-blend-mode: darken;
  background-color: #00000080;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

@media (max-width: 768px) {
  .contact-form-container {
    padding: 10px 0;
  }
}

.contact-form-container .contact-form-title {
  color: #fff;
}

.contact-form-title h2 {
  font-size: calc(1.325rem + 0.9vw);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: min(600px, 90vw);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #e0e0e0;
}

/* footer */
.footer {
  background-color: #1a1a1a;
  color: #fff;
  text-align: center;
  padding: 10px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.fa-solid {
  font-size: x-large;
  cursor: pointer;
}

.responsive-buttons {
  display: none;
}

.contact1 {
  display: none;
}

.exit {
  display: none;
}

/* Responsive Styles */
@media (max-width: 940px) {
  .exit {
    display: contents;
  }
  .nav-block {
    position: fixed;
    right: -240px;
    top: 0;
    background-color: #e0e7ef;
    color: #000;
    width: 240px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 100;
    padding: 20px;
  }

  .nav-block.active {
    right: 0;
  }

  .responsive-buttons {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .nav-option a {
    font-size: 1.2rem;
  }
  .contact {
    display: none;
  }
  .contact1 {
    display: contents;
  }
  #btn {
    cursor: pointer;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: larger;
    background-color: #3a0ca3;
    color: var(--primary-color);
    border: none;
  }
  .form-group textarea{
    width: 100%;
  }
}

#maill{
    text-decoration: none;
    color: white;
}

.submit-button{
    height: 34px;
    background-color: var(--bg-color-btn);
    color: var(--primary-color);
    border: none;
}